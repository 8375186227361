import React, { useState } from "react";
import Cat from "./components/categories/page";
// import BannerSuperior from "./components/bannerSuperior/page";
import Featureds from "./components/featureds/page";
import Header from "./components/header/page";
import Footer from "./components/footer/page";
import BannerSuperiorSL from "./components/bannerSuperiorSL/page";

export default function App() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);

  const handleCategorySelect = (categoryId, categoryName) => {
    setSelectedCategory(categoryId);
    setSelectedCategoryName(categoryName);
  };

  return (
    <div>
      <Header />
      <BannerSuperiorSL />
      <Cat onCategorySelect={handleCategorySelect} />
      <Featureds
        selectedCategory={selectedCategory}
        selectedCategoryName={selectedCategoryName}
      />
      <Footer />
    </div>
  );
}
