// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__0LuaC {
  margin-top: 2rem;
}

.style_lies__WqwIk {
  width: 7rem;
  padding: 0.5rem 0;
  font-size: 0.5rem;
  display: grid !important;
  place-items: center !important;
}

.style_imageCat__jNEEz {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 1px solid #e2a759;
  margin: 0;
}
.style_slick-slide__6gMEo img {
  text-align: center;
}

h2.style_nomCat__DjiRd {
  font-size: 1rem;
  text-align: center;
  padding-top: 0.4rem;
  text-transform: uppercase;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/categories/style.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,SAAS;AACX;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".container {\r\n  margin-top: 2rem;\r\n}\r\n\r\n.lies {\r\n  width: 7rem;\r\n  padding: 0.5rem 0;\r\n  font-size: 0.5rem;\r\n  display: grid !important;\r\n  place-items: center !important;\r\n}\r\n\r\n.imageCat {\r\n  width: 6rem;\r\n  height: 6rem;\r\n  border-radius: 50%;\r\n  border: 1px solid #e2a759;\r\n  margin: 0;\r\n}\r\n.slick-slide img {\r\n  text-align: center;\r\n}\r\n\r\nh2.nomCat {\r\n  font-size: 1rem;\r\n  text-align: center;\r\n  padding-top: 0.4rem;\r\n  text-transform: uppercase;\r\n  font-weight: 400;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__0LuaC`,
	"lies": `style_lies__WqwIk`,
	"imageCat": `style_imageCat__jNEEz`,
	"slick-slide": `style_slick-slide__6gMEo`,
	"nomCat": `style_nomCat__DjiRd`
};
export default ___CSS_LOADER_EXPORT___;
