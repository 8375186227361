import React from "react";
import style from "./style.module.css";

function page() {
  return (
    <div className={style.contenedor}>
      <a href="http://instagram.com" target="_blank" rel="noopener noreferrer">
        <img src="/instagram.svg" className={style.insta} alt="Instagram" />
      </a>
      <p>YPF FULL SAN PEDRO - Direccion 000 - Provincia</p>
      <p>fullypfsanpedro@gmail.com</p>
      <p>0388-3213213</p>
    </div>
  );
}

export default page;
