"use client";
import React, { useState, useEffect } from "react";
import { getMenuCat } from "../../helpers/getMenuCat";
import style from "./style.module.css";
import { NEXT_PUBLIC_APP_STRAPI } from "./../../config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Cat({ onCategorySelect }) {
  const [menuFinal, setMenuFinal] = useState([]);
  const NEXT_PUBLIC_APP_STRAPI = "https://fullypf.kobee.digital";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const menuData = await getMenuCat();
        // setMenuFinal(menuData);
        setMenuFinal(
          menuData.filter((x) => x.attributes.nombre !== "Destacados")
        );
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };
    fetchData();
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
  };

  const handleCategorySelect = (categoryId, categoryName) => {
    onCategorySelect(categoryId, categoryName);
  };

  return (
    <div className={style.container}>
      <Slider {...settings}>
        {menuFinal.map((menu) => (
          <div
            key={menu.attributes.image.data?.attributes.url}
            className={style.lies}
            onClick={() =>
              handleCategorySelect(menu.id, menu.attributes.nombre)
            }
          >
            <img
              className={style.imageCat}
              width={50}
              height={50}
              src={`${NEXT_PUBLIC_APP_STRAPI}${menu.attributes.image.data?.attributes.url}`}
              alt={menu.attributes.nombre}
            />
            <h2 className={style.nomCat}>{menu.attributes.nombre}</h2>
           
          </div>
        ))}
      </Slider>
      {/* <ul className={style.ules}>
        {menuFinal.map((menu) => (
          <li
            key={menu.id}
            className={style.lies}
            onClick={() =>
              handleCategorySelect(menu.id, menu.attributes.nombre)
            }
          >
            <img
              className={style.imageCat}
              width={50}
              height={50}
              src={`${NEXT_PUBLIC_APP_STRAPI}${menu.attributes.image.data?.attributes.url}`}
            />
            <h2 className={style.nomCat}>{menu.attributes.nombre}</h2>
          </li>
        ))}
      </ul> */}
    </div>
  );
}
