import { NEXT_PUBLIC_API_STRAPI } from "./../config";
export async function getBannerSuperior() {
  console.log("NEXT_PUBLIC_API_STRAPI:", NEXT_PUBLIC_API_STRAPI);
  // const res = await fetch(
  //   `${NEXT_PUBLIC_API_STRAPI}banner-superiors?populate=*`
  // );

  const res = await fetch(
    `https://fullypf.kobee.digital/api/banner-superiors?populate=*`
  );

  if (!res.ok) {
    throw Error("Error en el fetching de datos");
  }

  const { data } = await res.json();
  console.log(data);
  return data;
}
