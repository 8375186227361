import React from "react";
import style from "./style.module.css";

function page() {
  return (
    <div className={style.contenedor}>
      <img src="/logo-white.svg" alt="YPF FULL San Pedro" />
    </div>
  );
}

export default page;
