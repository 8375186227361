// import { NEXT_PUBLIC_API_STRAPI } from "./../config";

export async function getMenuCat() {
  // const res = await fetch(`${NEXT_PUBLIC_API_STRAPI}categorias?populate=*`);
  const res = await fetch(
    `https://fullypf.kobee.digital/api/categorias?populate=*`
  );
  if (!res.ok) {
    throw Error("Error en el fetching de datos");
  }

  const { data } = await res.json();
  return data;
}
