"use client";
import React, { useState, useEffect, useRef } from "react";
import { getBannerSuperior } from "../../helpers/getBannerSuperior";
import style from "./style.module.css";
// import { NEXT_PUBLIC_APP_STRAPI } from "../../config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BannerSuperiorSL({ onCategorySelect }) {
  const [bannerSuperior, setBannerSuperior] = useState([]);
  const sliderRef = useRef(null);
  const videosRefs = useRef([]);
  const NEXT_PUBLIC_APP_STRAPI = "https://fullypf.kobee.digital";
  // const handleBeforeChange = (oldIndex, newIndex) => {
  //   // Pausa el video del slide anterior
  //   if (videosRefs.current[oldIndex]) {
  //     videosRefs.current[oldIndex].pause();
  //   }
  // };

  // const handleAfterChange = (index) => {
  //   // Reproduce el video del slide actual
  //   if (videosRefs.current[index]) {
  //     videosRefs.current[index].play();
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerData = await getBannerSuperior();
        setBannerSuperior(
          bannerData.filter((x) => x.attributes.Activo === true)
        );
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };
    fetchData();
  }, []);

  // Renderiza el Slider
  return (
    <div>
      {bannerSuperior.length > 1 ? (
        <Slider
          ref={sliderRef}
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {bannerSuperior.map((menu) => (
            <div
              className={style.contenedor}
              key={menu.attributes.Imagen.data?.attributes.url}
            >
              {menu.attributes.Imagen.data?.attributes.url.endsWith(".jpg") ? (
                <img
                  className={style.imageCat}
                  // src={`${NEXT_PUBLIC_APP_STRAPI}${menu.attributes.Imagen.data?.attributes.url}`}
                  alt="Imagen"
                />
              ) : menu.attributes.Imagen.data?.attributes.url.endsWith(
                  ".mp4"
                ) ? (
                <video
                  className={style.videoBanner}
                  src={`${NEXT_PUBLIC_APP_STRAPI}${menu.attributes.Imagen.data?.attributes.url}`}
                  autoPlay
                  muted
                  loop
                  playsInline
                  width={600}
                  ref={(videoRef) => (videosRefs.current[0] = videoRef)} // Solo hay un video en este caso, por lo tanto, usamos el índice 0
                ></video>
              ) : (
                console.log(
                  "El archivo no es compatible con la extension .jpg o .mp4"
                )
              )}
            </div>
          ))}
        </Slider>
      ) : (
        bannerSuperior.map((menu) => (
          <div
            className={style.contenedor}
            key={menu.attributes.Imagen.data?.attributes.url}
          >
            {menu.attributes.Imagen.data?.attributes.url.endsWith(".jpg") ? (
              <img
                className={style.imageCat}
                src={`${NEXT_PUBLIC_APP_STRAPI}${menu.attributes.Imagen.data?.attributes.url}`}
                alt="Imagen"
              />
            ) : menu.attributes.Imagen.data?.attributes.url.endsWith(".mp4") ? (
              <video
                className={style.videoBanner}
                src={`${NEXT_PUBLIC_APP_STRAPI}${menu.attributes.Imagen.data?.attributes.url}`}
                autoPlay
                muted
                loop
                playsInline
                width={600}
                ref={(videoRef) => (videosRefs.current[0] = videoRef)} // Solo hay un video en este caso, por lo tanto, usamos el indice 0
              ></video>
            ) : (
              console.log(
                "El archivo no es compatible con la extension .jpg o .mp4"
              )
            )}
          </div>
        ))
      )}
    </div>
  );
}
