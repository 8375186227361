// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_imageCat__boW81,
.style_videoBanner__hvpNE {
  border: 0;
  margin: 0;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/bannerSuperiorSL/style.module.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,SAAS;EACT,WAAW;AACb","sourcesContent":[".imageCat,\r\n.videoBanner {\r\n  border: 0;\r\n  margin: 0;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageCat": `style_imageCat__boW81`,
	"videoBanner": `style_videoBanner__hvpNE`
};
export default ___CSS_LOADER_EXPORT___;
