// import { NEXT_PUBLIC_API_STRAPI } from "./../config";
export async function getFeatProds() {
  // const res = await fetch(`${NEXT_PUBLIC_API_STRAPI}productos?populate=*`);
  const res = await fetch(
    `https://fullypf.kobee.digital/api/productos?populate=*`
  );
  if (!res.ok) {
    throw Error("Error en el fetching de datos");
  }

  const { data } = await res.json();
  console.log(data);
  return data;
}
