// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_contenedor__vexeY {
  width: 100%;
  background-color: #e5af9e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf","sourcesContent":[".contenedor {\r\n  width: 100%;\r\n  background-color: #e5af9e;\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  padding: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": `style_contenedor__vexeY`
};
export default ___CSS_LOADER_EXPORT___;
