// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_contenedor__RPGox {
  width: 100%;
  background-color: #e5af9e;
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.3rem;
}

.style_insta__QolTr {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".contenedor {\r\n  width: 100%;\r\n  background-color: #e5af9e;\r\n  text-align: center;\r\n  padding: 1rem;\r\n  color: #ffffff;\r\n  font-size: 0.8rem;\r\n  line-height: 1.3rem;\r\n}\r\n\r\n.insta {\r\n  width: 1.5rem;\r\n  height: 1.5rem;\r\n  margin-bottom: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": `style_contenedor__RPGox`,
	"insta": `style_insta__QolTr`
};
export default ___CSS_LOADER_EXPORT___;
