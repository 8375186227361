import React, { useState, useEffect } from "react";
import { getFeatProds } from "../../helpers/getFeatProds";
import style from "./style.module.css";
// import { NEXT_PUBLIC_APP_STRAPI } from "./../../config";

export default function Featureds({ selectedCategory, selectedCategoryName }) {
  const [featProds, setFeatProds] = useState([]);
  const NEXT_PUBLIC_APP_STRAPI = "https://fullypf.kobee.digital";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const featData = await getFeatProds();
        setFeatProds(featData);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };
    fetchData();
  }, []);

  const filteredProds = selectedCategory
    ? featProds.filter((prod) =>
        prod.attributes.categorias.data.some(
          (categoria) => categoria.id === selectedCategory
        )
      )
    : featProds.filter((prod) =>
        prod.attributes.categorias.data.some((categoria) => categoria.id === 6)
      );

  return (
    <div>
      <h1 className={style.hachedos}>
        {selectedCategoryName ? selectedCategoryName : "Recomendados"}
      </h1>

      <div className={style.contenedor}>
        {filteredProds.map((prod) => (
          <div className={style.tarjeta} key={prod.attributes.nombre}>
            {/* <img className={style.imageMenu} src={`${NEXT_PUBLIC_APP_STRAPI}${prod.attributes.cover.data?.attributes.url}`} alt="" /> */}
            <div className={style.tituloprecio}>
              <div className={style.tarjetadesc}>
                <h3 className={style.titulo}>{prod.attributes.nombre}</h3>
                <p className={style.precio}>{prod.attributes.precio}</p>
              </div>
              <p className={style.descr}>{prod.attributes.descripcion}</p>
              <hr className={style.divider} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
